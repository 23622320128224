import React from 'react'

import Navbar from '../Navbar'
import Contact from '../Contact'
import Footer from '../Footer'
import TermsAndConditionsTitlePage from '../TermsAndConditions/TermsAndConditionsTitlePage'
import TermsAndConditionsContent from '../TermsAndConditions/TermsAndConditionsContent'

const TermsAndConditions = () => {
    return (
        <div>
            <Navbar />
            <TermsAndConditionsTitlePage />
            <TermsAndConditionsContent />
            <Contact />
            <Footer />
        </div>
    )
}

export default TermsAndConditions