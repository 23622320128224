import React, { useState, useRef, useEffect } from "react";
import { HiLocationMarker } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlinePhone } from "react-icons/hi";
import emailjs from '@emailjs/browser';

const Contact = () => {
    
    // funtion to display message after the user click on send button 
    const [sentFeedback, setFeedback] = useState("transparent");
    const handleSubmit = (e) => {
        e.preventDefault();
        if(isMessageEmpty.trim() === '' || isEmailEmpty.trim() === ''){
            setFeedback('red');
            setMessageSent(false);
        }else{
            //setFeedback('black');
            //console.log("It's "+isMessageEmpty.trim());
            sendEmail(e);
        }
    }
    // function to check if the message is empty
    const [isMessageEmpty, setMessageEmpty] = useState('');
    const [isEmailEmpty, setEmailEmpty] = useState ('');
    const [isMessageSent, setMessageSent] = useState(false);


    
    // function from emailjs 
    const form = useRef();
    const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_3g8f57h', 'template_9jmcrjo', form.current, {
        publicKey: 'P5zYqFkixFdHALmD9',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setMessageSent(true);
          setFeedback('black');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

    return(
        <section className="grid lg:grid-cols-2 gap-4 w-auto bg-[#D3CAB2]"
        id="contact">
            {/* left size contact info */}
            <div className="text-[#333333] pl-[15%] sm:pl-[20%] lg:pl-[6%]">
                <h1 className="text-3xl lg:text-4xl font-semibold m-3 mb-6 pt-3 lg:m-12">Contact us</h1>
                <table className="table-auto max-w-[35%] m-3 lg:m-12 text-xl">
                    <tbody>
                        <tr>
                            <td><HiOutlinePhone size={35} /> </td>
                            <td className="pl-3"><a className="hover:text-blue-600 hover:underline" href="tel:+250 0792575329">+250 0792575329</a> </td>
                        </tr>
                        <tr><td></td><td className="pb-3 pl-3"><a className="hover:text-blue-600 hover:underline" href="tel:+250 0788307865"> +250 0788307865</a></td></tr>
                        <tr>
                            <td><HiOutlineMail size={35}/></td>
                            <td className="p-3"><a className="hover:text-blue-600 hover:underline" href="mailto:info@farmfreshltd.com">info@farmfreshltd.com</a></td>
                        </tr>
                        <tr>
                            <td><HiLocationMarker size={35}/> </td>
                            <td className="p-3"><a className="hover:text-blue-600 hover:underline" href="https://maps.app.goo.gl/cHWCWpyVwapjZMtB8">Second floor SF11, kk561 Street, Gikondo Sector, Kicukiro District, Kigali City, Rwanda</a></td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
            
            {/* right side form */}
            <div className="text-xl flex justify-center items-center pt-10">
            <form ref={form} onSubmit={handleSubmit}>
                <table className="max-w-[2000px] w-auto table-auto mt-3 lg:mt-30 ml-6">
                    <tbody>
                        <tr>
                            {/* <td><label className="p-6">Name <span className="text-rose-800">*</span></label></td> */}
                            <td><input type="text" 
                                       name="user_name" 
                                       placeholder="    Name" 
                                       className="p-3 my-3 rounded-2xl w-[300px] md:w-[500px] h-[50px] border shadow-lg"></input></td>
                        </tr>
                        <tr className="pb-10">
                            {/* <td><label className="p-6">Email <span className="text-rose-800">*</span></label></td> */}
                            <td><input type="email" 
                                       name="user_email" 
                                       onChange={e => setEmailEmpty(e.target.value)} 
                                       placeholder="    Email" 
                                       className="p-3 my-3 rounded-2xl w-[300px] md:w-[500px] h-[50px] border shadow-lg" /></td>
                        </tr>
                        <tr className="mb-6">
                            {/* <td><label className="p-6">Message</label></td> */}
                            <td><textarea name="message"
                                          onChange={e => setMessageEmpty(e.target.value)} 
                                          placeholder="    Message" 
                                          className="p-3 my-6 rounded-2xl w-[300px] md:w-[500px] h-[150px] border shadow-lg" /></td>
                        </tr>
                    </tbody>
                </table>
                <input type="submit" value="Send"
                className="ml-6 p-3 w-[300px] md:w-[500px] bg-amber-500 text-white rounded-2xl
                hover:bg-blue-500"/>
                {!isMessageSent && <p className="m-3 p-3 mb-[8%]" style={{ color: sentFeedback }}>Infomation is required!</p>}
                {isMessageSent && <p className="m-3 p-3 mb-[8%]" style={{ color: sentFeedback }}> ✅ Message Sent! Thanks for your interest! </p>}
                </form>
            </div>
        </section>
    )
}

export default Contact