import Navbar from './components/Navbar.jsx'
import Hero from './components/Hero.jsx'
import Products from './components/Products.jsx';
import Logistics from './components/Logistics.jsx';
import Storage from './components/Storage.jsx';
import Contact from './components/Contact.jsx';
import Footer from './components/Footer.jsx';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Products />
      <Logistics />
      <Storage />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
