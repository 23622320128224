import React, {useState, useEffect} from 'react';
import {HiMenuAlt3, HiOutlineX} from 'react-icons/hi';

import { Link } from "react-router-dom"

const Navbar = () => {
    /* function to change state of the navbar */
    const [nav, setNav] = useState(false);
    const handleNav = () => {
        setNav(!nav);
        if(!nav){
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'scroll'
        }
    }

    // function to change the text color of nav bar when the user scrolls 
    const [textColor, setTextColor] = useState('white');
    const [logoColor, setLogoColor] = useState('white');
    useEffect(()=> {
        const changeColor = () => {
            if(window.scrollY >= 700) {
                setLogoColor('transparent')
                setTextColor('#000000')
            }else{
                setLogoColor('#ffffff')
                setTextColor('#ffffff')
            }
        }
        window.addEventListener('scroll', changeColor)
    }, [])


    /* function to change state of the language 
    const [language, setLanguage] = useState(false);
    const changeLanguage = () => {
        setLanguage(!language);
    }
    */

    return (
        <div className='w-full fixed top-0 flex justify-between items-top z-10'>
            {/* company name */}
            <h1 style={{color:`${logoColor}`}} className='text-white font-bold p-6 text-2xl z-20'><Link to='/'>FARMFRESH</Link></h1>

            {/* drop down language menu 
            <div className='fixed top-0 md:left-[85%] md:p-6 w-full md:w-auto h-screen z-10 flex-col'>
                <select className="bg-white text-black px-2 md:px-4 py-1 md:text-wrap rounded-full">
                    <option value="en">EN</option>
                    <option value="cn">简体中文</option>
                    <option value="fr">French</option>
                </select>
            </div>
            */}

            {/* nav menu icon */}
            {nav ? (
            <HiOutlineX onClick={handleNav}  
            className='z-20 text-white cursor-pointer md:w-auto sm:w-auto w-full absolute top-6 left-[40%] md:left-[95%] hover:scale-110 duration-100' size={30}/>
            ) : (
            <HiMenuAlt3 onClick={handleNav} style={{color:`${textColor}`}} size={30}
            className='z-20 text-white cursor-pointer md:w-auto sm:w-auto w-full absolute top-6 left-[40%] sm:left-[95%] hover:scale-110 duration-100'/>
            )}
            {/* <HiMenuAlt3 onClick={handleNav}  style={{color:`${textColor}`}}  */}
            {/* className='z-20 text-white cursor-pointer md:w-auto sm:w-auto w-full absolute top-6 left-[40%] md:left-[95%]' size={30} /> */}

            {/* nav menu content */}
            <div className={
                nav 
                ? 'fixed text-gray-300 left-0 top-0 w-full h-screen bg-black/95 px-4 py-10 flex-col z-10'
                : 'hidden'
                }
            >
                <ul className='flex flex-col w-full h-full items-center justify-center'>
                    <li className='font-bold text-3xl p-5 hover:scale-110 duration-100'><Link to='/' onClick={handleNav}>Home</Link></li>
                    <li className='font-bold text-3xl p-5 hover:scale-110 duration-100'><a href='./#products' onClick={handleNav}>Products</a></li>
                    <li className='font-bold text-3xl p-5 hover:scale-110 duration-100'><Link to='/AboutUs' onClick={handleNav}>About Us</Link></li>
                    <li className='font-bold text-3xl p-5 hover:scale-110 duration-100'><Link to='/Contact' onClick={handleNav}>Contact Us</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar