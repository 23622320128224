import React from 'react'

import Navbar from '../Navbar'
import Contact from '../Contact'
import Footer from '../Footer'
import ChaotianChiliIntro from '../../components/ChaotianChili/ChaotianChiliIntro'
import IndianChiliGallery from '../../components/IndianChili/IndianChiliGallery'
import ChaotianChiliBasicInfo from '../../components/ChaotianChili/ChaotianChiliBasicInfo'
import ChaotianChiliTimeline from '../ChaotianChili/ChaotianChiliTimeline'


const ChaotianChili = () => {
    return (
        <div>
            <Navbar />
            <ChaotianChiliIntro />
            <ChaotianChiliBasicInfo />
            <ChaotianChiliTimeline />
            <IndianChiliGallery />
            <Contact />
            <Footer />
        </div>
    )
}

export default ChaotianChili