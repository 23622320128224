import React from 'react'

import Navbar from '../Navbar'
import Contact from '../Contact'
import Footer from '../Footer'

import ContactDetails from '../Contact/ContactDetails'
import ContactTitle from '../Contact/ContactTitle'


const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <ContactTitle />
            <ContactDetails />
            <Contact />
            <Footer />
        </div>
    )
}

export default AboutUs