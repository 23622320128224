// Card.js
import React from 'react';

const Card = ({ imageSrc, title, description }) => {
  return (
    <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg text-[#333333]  bg-[#D3CAB2]  
                  hover:bg-[#A68059] hover:text-white hover:scale-105 duration-300">
      {/* Image */}
      <img className="w-full h-48 object-cover" src={imageSrc} alt={title} />

      {/* Content */}
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-base">{description}</p>
      </div>
    </div>
  );
};

export default Card;
