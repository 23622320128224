import React from 'react'

import Navbar from '../Navbar'
import Contact from '../Contact'
import Footer from '../Footer'

import AboutUsTitle from '../AboutUs/AboutUsTitle'
import AboutWhoWeAre from '../AboutUs/AboutWhoWeAre'

const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <AboutUsTitle />
            <AboutWhoWeAre />
            <Contact />
            <Footer />
        </div>
    )
}

export default AboutUs