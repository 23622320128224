import React from 'react'


const TermsAndConditionsContent = () => {
    return (
        <div className='w-full p-12'>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>1. Introduction</h2>
        <p className='text-base text-[#333333] pb-6'>Welcome to the FarmFresh International LTD website. These Terms and Conditions govern your use of our website and services. By accessing or using our website, you agree to comply with and be bound by these Terms. If you do not agree with any part of these Terms, you must not use our website.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>2. Use of the Website</h2>
        <p className='text-base text-[#333333] pb-6'>You agree to use the website only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else’s use and enjoyment of the website. Prohibited behavior includes but is not limited to, harassment, hacking, or distributing harmful software.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>3. Intellectual Property</h2>
        <p className='text-base text-[#333333] pb-6'>All content on the website, including text, graphics, logos, and images, is the property of the FarmFresh International LTD or its content suppliers and is protected by copyright and intellectual property laws. Unauthorized use or reproduction of any content is prohibited.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>4. User Accounts</h2>
        <p className='text-base text-[#333333] pb-6'>If you create an account on our website, you are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>5. Disclaimer of Warranties</h2>
        <p className='text-base text-[#333333] pb-6'>Our website and its content are provided on an “as is” basis. We make no warranties or representations of any kind, express or implied, regarding the operation of our website or the information, content, materials, or products included on the website.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>6. Limitation of Liability</h2>
        <p className='text-base text-[#333333] pb-6'>To the fullest extent permitted by law, the FarmFresh International LTD website shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the website. Our liability for any claim arising from your use of the website is limited to the amount paid by you for access to the website, if any.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>7. Links to Third-Party Websites</h2>
        <p className='text-base text-[#333333] pb-6'>Our website may contain links to third-party websites that are not owned or controlled by the FarmFresh International LTD website. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>8. Modification of Terms</h2>
        <p className='text-base text-[#333333] pb-6'>We reserve the right to update or modify these Terms at any time. Any changes will be posted on this page, and it is your responsibility to review these Terms periodically. Continued use of the website after any modifications constitutes your acceptance of the revised Terms.</p>
        
        <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>9. Governing Law</h2>
        <p className='text-base text-[#333333] pb-6'>These Terms are governed by and construed in accordance with the laws of Rwanda. Any disputes arising from these Terms or your use of the website will be subject to the exclusive jurisdiction of the courts of Rwanda.</p>
        

        </div>
    )
}

export default TermsAndConditionsContent