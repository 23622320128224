import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {BrowserRouter,HashRouter, Routes, Route} from 'react-router-dom';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import IndianChili from './components/Router/IndianChili';
import ChaotianChili from './components/Router/ChaotianChili';
import Stevia from './components/Router/Stevia';
import TermsAndConditions from './components/Router/TermsAndConditions';
import AboutUs from './components/Router/AboutUs';
import ContactUs from './components/Router/ContactUs';



const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <ScrollToTop />
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/IndianChili' element={<IndianChili />} />
      <Route path='/ChaotianChili' element={<ChaotianChili />} />
      <Route path='/Stevia' element={<Stevia />} />
      <Route path='/TermsAndConditions' element={<TermsAndConditions />} />
      <Route path='/AboutUs' element={<AboutUs />} />
      <Route path='/Contact' element={<ContactUs />} />

      </Routes>
  </BrowserRouter>
);

