import React from 'react'
import companyemployees1 from '../../assets/aboutUs/companymeeting1.jpg'
import farmers1 from '../../assets/aboutUs/farmers3.jpg'
import office from '../../assets/aboutUs/office1.jpg'


const AboutUsTitle = () => {
    return (
        <div className=''>
            {/* text intro */}
            <div className='flex justify-center text-[#333333]'>
            <p className='text-xl md:text-2xl lg:text-center p-12'>We are a passionate and professional group dedicated to revolutionizing the way food is grown and consumed across the continent.
            <br/>Based in Rwanda, we specialize in the export of <span className='text-[#710304] font-semibold'>high-quality</span> chili peppers, stevia and other food products, driven by our commitment to <span className='text-[#710304] font-semibold'>organic </span> practices and <span className='text-[#710304] font-semibold'>sustainable </span> agriculture.</p>
            </div>
            
            {/* text + image, our mission */}
            <div className='grid grid-cols-4 grid-rows-6 lg:grid-rows-4 lg:grid-row-4 gap-4 px-12 pb-12 max-h-[1080px]'>
                <div className='col-start-1 row-start-3 col-span-4 row-span-2
                                lg:col-start-1 lg:row-start-1 lg:col-span-1 lg:row-span-1 order-1 
                                w-auto text-[#f6f2e8] bg-[#a68059]'>
                    <p className='p-3 w-full h-full text-sm åxl:text-base'>
                        We are proud to be part of Africa’s growing food export industry. We aim to promote and export Rwanda's diverse agricultural products, including coffee, tea, fruits, vegetables, and spices. 
                        Our goal is to elevate the profile of Rwandan agriculture on the global stage, showcasing the rich flavors and exceptional quality of our produce.</p>
                </div>
                <div className="lg:col-start-1 lg:row-start-2 lg:col-span-1 lg:row-span-3 order-2
                                col-start-1 col-span-4 row-start-2 row-span-1">
                    <img className='object-cover w-full h-full' src={office} />
                </div>
                <div className="lg:col-start-2 lg:row-start-1 lg:row-span-4 lg:col-span-2 order-3
                                col-start-1 row-start-5 col-span-4 row-span-2">
                    <img className='object-cover w-full h-full' src={farmers1} />
                </div>
                <div className="lg:visible lg:col-start-4 lg:row-start-1 lg:row-span-1
                                invisible">
                    <img className='object-cover w-full h-full' src={companyemployees1} />
                </div>
                <div className="lg:col-start-4 lg:row-start-2 lg:row-span-3 
                                col-start-1 row-start-1 col-span-4 row-span-1 bg-[#a68059] text-[#f6f2e8]">
                    <p className='p-3 w-full h-full text-sm xl:text-base'>
                    Established in 2023, we have rapidly grown into a dynamic organization of around 60 dedicated professionals, with a network of over 250 farmers actively cultivating our fields.
                        <br /><span className='invisible lg:visible'>Our success is built on a foundation of expertise and collaboration. We recruit a team of educated and experienced agronomists, providing them with specialized training and planting expertise to ensure the highest standards of cultivation. Our agronomists work closely with our farmers to implement best practices and optimize crop yields.</span>
                        <br /><span className='invisible lg:visible'>Our commitment to excellence extends beyond the farm. We have established sales contracts with clients from France, China, and Japan, positioning our products on the global stage and demonstrating our ability to meet international quality standards.</span>
                        <br /><span className='invisible lg:visible'>At FarmFresh, we are dedicated to building a sustainable and prosperous future for Africa through organic farming and responsible food export. We invite you to join us on our journey as we continue to grow, innovate, and make a positive impact worldwide, one spice and food product at a time.</span></p>
                </div>
            </div> 

        </div>
    )
}

export default AboutUsTitle