import React from 'react'



const ChaotianChiliIntro = () => {
    return (
        <div className='w-full h-screen'>
            {/*background img*/}
            <img className='top-0 left-0 w-full h-screen object-cover' src={'https://images.pexels.com/photos/25368350/pexels-photo-25368350/free-photo-of-view-of-dried-peppers-hanging-on-the-strings-at-the-food-market.jpeg'} alt="Chaotian Chili Background" />
            {/*background black mask 蒙版*/}
            <div className='bg-black/40 absolute top-0 w-full h-screen' />

            {/* Text */}
            <div className='absolute top-0 w-full h-full flex justify-center items-center text-white'>
                <div>
                    <p className='p-3 text-lg md:text-4xl'>Elevating Dishes with Rich Heat</p>
                    <h1 className='flex justify-center font-bold text-5xl md:text-7xl drop-shadow-2xl'>Chaotian Chili</h1>
                </div>
            </div>

        </div>
    )
}

export default ChaotianChiliIntro