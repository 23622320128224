import React from 'react'
import cityview from '../../assets/rwandacityview.jpg'

const AboutUsTitle = () => {
    return (
        <div className='w-full h-screen'>
            {/*background img*/}
            <img className='top-0 left-0 w-full h-screen object-cover' src={cityview} alt="buildings" />
            {/*background black mask 蒙版*/}
            <div className='bg-black/40 absolute top-0 w-full h-screen' />

            {/* Text */}
            <div className='absolute top-0 w-full h-full flex justify-center items-center text-white'>
                <div>
                    <p className='p-3 text-xl md:text-4xl'>FarmFresh International Ltd</p>
                    <h1 className='flex justify-center items-center font-bold text-4xl md:text-7xl drop-shadow-2xl'>About us</h1>
                </div>
            </div>

        </div>
    )
}

export default AboutUsTitle