import React from 'react'
import logo from '../assets/logo.png'

import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div>
            {/* logo & links */}
            <div  className='flex flex-wrap justify-between items-center mb-3'>
                {/* logo */}
                <div className="flex items-center">
                    <img class="object-cover h-24 w-24" src={logo} alt="farmfresh logo"/>
                    <span className='self-center text-2xl font-semibold'><Link to="/">FarmFresh International</Link></span>
                </div>
                
                {/* links */}
                <div className='flex flex-wrap items-center text-gray-500 text-xl mr-0 md:mr-24'>
                    <p className="hover:underline mx-6 md:mx-12"><Link to="/AboutUs">About Us</Link></p>
                    <p className="hover:underline mx-6 md:mx-12"><a href="#">FAQs</a></p>
                    <p className="hover:underline mx-6 md:mx-12"><a href='https://www.linkedin.com/company/farmfresh-international-ltd/jobs'>Careers</a></p>
                    <p className="hover:underline mx-6 md:mx-12"><a href="#">Investors</a></p>
                    <p className="hover:underline mx-6 md:mx-12"><Link to="/TermsAndConditions">Terms & Conditions</Link></p>
                </div>
            </div>
           

        {/* copyright notice */}
        <hr class="border-gray-200 sm:mx-auto dark:border-gray-700" />
        <div>
            <p className='text-xl text-gray-500 sm:text-center m-6'>© FARMFRESH INTERNATIONAL LTD 2024 - All Rights Reserved.</p>

        </div>
        </div>
    )
}

export default Footer