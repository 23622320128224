import React from 'react'



const ChaotianChiliTimeline = () => {
    const events = [
        { week: 'Week 0', 
          title: 'Chili Seeds Selection and Preparation', 
          description: 'We sourced the Indian Teja chili seeds directly from India and Chaotian chili seeds directly from China, with careful selection to guarantee their superior quality and rich, authentic heat, as this region is celebrated for its exceptional chili varieties. We do not use any pesticides or chemical fertilizers in our seedbeds; instead, we expose the soil to sunlight by deep plowing during the dry season to effectively kill pests and diseases. Before planting, we evenly spread 25 tons of composted well decomposed cow and sheep manure per hectare, which has been fermented for at least four months, and then perform another deep plowing.' },
        { week: 'Week 1', 
          title: 'Chili Seeds Sowing', 
          description: 'After soaking the chili seeds in warm water for hours, the seeds are sown in the seedbed at regular intervals. Rows are dug 3-4 cm deep every 20 cm, and the soaked seeds are evenly spread at the bottom of the trenches. Water is then applied, and plant straw is used to cover the area to reduce moisture evaporation and protect the seedlings from sun exposure. For the first week, water is applied every evening after sunset, and within three days, a few seedlings appear, with most emerging after a week.' },
        { week: 'Week 5', 
          title: 'Transplanting and Field Management', 
          description: 'The chili pepper plants should be dark green and about 20 cm tall, and ready to be moved to the field. Similar to the seedbed, we use 25 tons of organic fertilizer per hectare and covering the soil with rice or corn straw to keep it moist and prevent weeds. Since chili peppers have shallow roots, we plant them carefully. We space the holes by 70 cm * 80 cm apart and dig each hole 15 cm deep. We plant the seedlings with 5 cm of soil covering their roots. Every 15 days, we add 3 cm of soil around the plants to support healthy root growth and help the plants produce more fruit.' },
        { week: 'Week 7 - Week 14',
          title: 'Chili Flower Buds and First Fruits',
          description: 'By this stage, chili pepper plants reach a height of approximately 40 cm and start to develop flower buds. The plants produce their first fruit at approximately 60 days after transplanting. These first fruits are typically harvested after 15 days to encourage the plants to focus their energy on producing larger and more uniform fruit, enhancing overall yield.'},
        { week: 'Week 22',
          title: 'Harvest Time',
          description: 'This period marks the peak fruiting stage, with each plant often bearing over 120 peppers. The peppers gradually ripen, turning red. At this point, manual harvesting begins, with chili peppers collected in batches to ensure optimal ripeness and quality.'},
      ];


    return (
        <div className='w-full'>
            {/* Timeline */}
            <div className='text-[#333333] flex justify-center items-center p-12 md:px-[20%]' id='story'>
                <ol>
                    <li className='relative flex gap-6'>
                        <div>
                                {events.map((event) => (
                                    <div>
                                        {/* Dot */}
                                        <div className='before:absolute before:left-[5.5px] before:h-dvh before:max-h-[500px] before:w-[1px] before:bg-[#a68059]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" className='fill-[#a68059]' viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>
                                        
                                        {/* Content */}
                                        </div>
                                        <div className='pl-6'>
                                        <p className='text-base font-normal leading-none text-gray-400 pb-3'>{event.week}</p>
                                        <h3 className='text-xl text-[#6e211d] font-semibold'>{event.title}</h3>
                                        <p className='pb-3 text-base font-normal text-justify'>{event.description}</p>
                                        </div>
                                    </div>
                                ))
                                }
                        </div>
                    </li>
                </ol>
            </div>

        </div>
    )
}

export default ChaotianChiliTimeline