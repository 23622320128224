import React from "react"
import p1 from '../assets/products/IndianS17.jpg'
import p2 from '../assets/products/ChaotianChili.jpg'
import p3 from '../assets/products/SteviaFresh.jpg'
import p4 from '../assets/products/SteviaTea.jpg'

import { Link } from "react-router-dom"

const ProductsData = [
    {
        id: 1,
        img: p1,
        link: "IndianChili",
        productName: "Indian Teja Chili",
        description: "With premium quality and intense flavor, Teja is prefect for hot pot seasoning production. Seeds sourced directly from India, we ensure authenticity and original flavor.",
    },
    {
        id: 2,
        img: p2,
        link: "ChaotianChili",
        productName: "Chaotian Chili",
        description: "Indulge in the rich, fiery taste of Chinese Chaotian chili, a staple in Sichuan cuisine. Seeds sourced directly from China, ensuring authenticity and exceptional taste.",
    },
    {
        id: 3,
        img: p3,
        link: "Stevia",
        productName: "Fresh Stevia",
        description: "Stevia, strictly produced without pesticides or chemical fertilizers, serves as an ideal sugar substitute for coffee-mate or zero-sugar drinks.",
    },
    {
        id: 4,
        img: p4,
        link: "Stevia",
        productName: "Stevia Tea",
        description: "Stevia tea offers a natural sweetener option with potential health benefits, such as aiding in weight management and regulating blood sugar levels."
    }
]

const Products = () => {
    return (
        <div className="w-full pt-[2%] pb-[20%] lg:pb-[10%]" id="products">
            {/* title */}
            <div className="w-full text-center p-10 xl:p-20">
                <h1 className="font-bold text-3xl text-[#333333] md:text-5xl mb-6">Our Products</h1>
                <p className="text-xl text-gray-600 px-[10%]">
                    The chili cultivation area covers 300 hectares, with 240 hectares dedicated to the cultivation of <span className="font-bold">Indian Teja chillies</span>.
                We provide both dried and fresh chillies throughout the year.
                <span className="font-bold"> Stevia</span> is currently in the 100% organic cultivation stage.
                </p>
            </div>

            {/* products */}
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-20 md:gap-5 place-items-center">
                {ProductsData.map((data) => (
                    <Link to={data.link}
                    className="rounded-2xl bg-[#D3CAB2] mx-[20%] mt-[30%] text-[#333333]
                    hover:bg-[#A68059] hover:text-white relative shadow-xl duration-high group 
                    hover:scale-105 duration-300">
                    {/* image */}
                    <div className="relative flex justify-center -top-20">
                        <img
                        className="object-cover w-36 h-36 rounded-full" 
                        alt={data.productName}
                        src={data.img} />
                    </div>

                    {/* description */}
                    <div className="text-center">
                        <h1 className="text-lg md:text-xl font-bold mb-4">{data.productName}</h1>
                        <p className="text-sm md:text-base max-w-[350px] max-h-[500px] px-4 pb-12">{data.description}</p>
                    </div>

                    {/* click to learn more button */}
                    
                    </Link>
                ))}
            </div>

        </div>














       
    )
}

export default Products;