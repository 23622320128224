import React from "react";

const BannerLogisticInfo = {
    backgroundImage: `url(${"https://images.pexels.com/photos/1554646/pexels-photo-1554646.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "30%",
    width: "100%",
};

const Logistics = () => {
    return(
        <div className='w-full'>
            <div className="bg-gray-100"
            style={BannerLogisticInfo}>
                {/* text  */}
                <div className="bg-black/40 w-full p-10 text-white flex flex-wrap">
                <p className="text-xl pl-[5%] pb-4">INTERNATIONAL LOGISTICS</p>
                <h1 className="text-2xl md:text-3xl px-[5%]">For year-round availability, we offer a steady supply of both dried and fresh chilis and stevia. Our prompt shipment arrangements ensure timely delivery according to your requirements. We support both CIF and FOB options to accommodate your shipping preferences.</h1>
                </div>
            </div>

            
            
        </div>
    )
}

export default Logistics;