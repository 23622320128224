import React from 'react'
import warehouse1 from '../assets/storage/warehouse1.jpg'
import warehouse2 from '../assets/storage/warehouse2.jpg'
import warehouse3 from '../assets/storage/warehouse3.jpg'
import transportation from '../assets/storage/transportation.jpg'
import office from '../assets/storage/office.jpg'

const Storage = () => {
    return (
        <div className='max-w-[2000px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4' id="infrastructure">
            {/* left size img */}
            <div className='grid grid-cols-2 grid-rows-6 h-[80vh]'>
                <img className='row-span-3 object-cover w-full h-full p-2 hover:scale-105 duration-300' 
                src={transportation} 
                alt="truck" />

                <img className='row-span-2 object-cover w-full h-full p-2 hover:scale-105 duration-300' 
                src={warehouse1} 
                alt="warehouse1" />

                <img className='row-span-2 object-cover w-full h-full p-2 hover:scale-105 duration-300' 
                src={warehouse2}
                alt="warehouse2" />

                <img className='row-span-3 object-cover w-full h-full p-2 hover:scale-105 duration-300' 
                src={warehouse3} 
                alt="warehouse3" />

                <img className='row-span-2 object-cover w-full h-full p-2 hover:scale-105 duration-300' 
                src={office} 
                alt="office" />
            </div>

            {/* right side text */}
            <div className='flex flex-col h-full justify-center p-6 text-[#333333]'>
                <h1 className='text-3xl md:text-5xl font-semibold'>Our Infrastructure</h1>
                <p className='text-xl py-12'>At our facilities, we boast warehouses strategically located in Kigali, Eastern, and Central regions to ensure efficient operations and timely delivery. With 3 drying machines and 15 drying sheds, our daily processing capacity reaches an impressive 50 tons of fresh chili peppers.</p>
                <p className='text-xl pb-12'>Our headquarters, situated in the capital city of Rwanda, Kigali, serves as the central hub of our operations. With a dedicated team of over 60 personnel, including 8 production area managers, we ensure seamless management and coordination across our business.</p>
            </div>
        </div>
    )
}

export default Storage