import React from 'react'
import Card from './Card.jsx'
import companyimg from '../../assets/aboutUs/office1.jpg'
import { Link } from "react-router-dom"


const ContactDetails = () => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 w-full h-auto pt-6 pb-6'>
            {/* Left Panel */}
            <div className='p-6 flex justify-center items-center'>
                <Link to='/AboutUs' > <Card imageSrc={companyimg}
                title="FarmFresh International Ltd. is"
                description="Rwanda's premier provider and exporter of high-quality organic food products. Our headquarter is located in the capital city of Rwanda, Kigali. Speak to one of our specialist to request a meeting today! Click to learn more." /> </Link>
            </div>
            {/* Right Panel */}
            <div className='p-6 text-[#333333]'>
                <h3 className='text-2xl text-[#A68059] font-bold mb-6'>What we can help?</h3>
                {/*分割线 */}
                <hr class="border-gray-300 sm:mx-auto" />
                <h4 className='text-lg text-[#6e211d] font-semibold mt-3'>Business Hours and Collaboration Invitation</h4>
                <p>Our business hours are 9.00 AM to 5.30 PM (GMT+2), Monday to Friday. For International clients, please contact us via the form below to schedule a meeting online.</p>
                <h4 className='text-lg text-[#6e211d] font-semibold mt-3'>Request a Sample</h4>
                <p>We’re excited to offer you the opportunity to experience our products firsthand before making a commitment. To request a sample, simply fill out the form below. Our team will review your request and send you a sample as soon as possible.</p>
                <h4 className='text-lg text-[#6e211d] font-semibold mt-3'>For Farmers: Collaboration Requests</h4>
                <p>If you meet the qualifications and are interested be a part of our family, we warmly encourage you to reach out to us for a discussion.</p>
            </div>

        </div>
    )
}

export default ContactDetails