import React from 'react'



const SteviaTimeline = () => {
    const events = [
        { week: 'Week 0', 
          title: 'Stevia Preparation', 
          description: 'Before planting, the agricultural land undergoes tillage and sun exposure to prepare the soil. Approximately 25 tons of well-composted cow and sheep manure per hectare is evenly spread and mixed into the soil. Prior to transplanting, beds are prepared with a height of 30 cm and a width of 90 cm - 100 cm, per Hectare around 80,000 to 100,000 stevia plants can be planted. The farming team consists of 5 workers per hectare, with each responsible for 2,000 square meters and daily recording of growth conditions.' },
        { week: 'Week 1 - Week 4', 
          title: 'Stevia Cuttings and Propagation', 
          description: 'Stevia plants are propagated using cuttings. For this method, two pairs of leaves and a stem cutting of approximately 4-5 cm in length are taken from the plant’s top and inserted into seedbeds. The cuttings are left to root before being transplanted into the field.' },
        { week: 'Week 5', 
          title: 'Transplanting and Field Management', 
          description: 'Once the cuttings have developed roots, they are transplanted into the prepared beds. The newly transplanted stevia plants begin to acclimate to their new environment and start initial growth.' },
        { week: 'Week 12',
          title: 'Stevia Growth Phase',
          description: 'Stevia plants establish a root system, grow and mature. By this stage, the plants start to reach a height of approximately 30-40 cm. Proper irrigation and pest management are crucial to support the plants development.'},
        { week: 'Week 20',
          title: 'Harvest Time',
          description: 'Harvesting is carried out manually to ensure the leaves remain green and organic. The leaves are carefully hand-harvested, laid out for drying, and sorted without the use of machinery, maintaining the purity and quality of the stevia.'},
      ];


    return (
        <div className='w-full'>
            {/* Timeline */}
            <div className='text-[#333333] flex justify-center items-center p-12 md:px-[20%]' id='story'>
                <ol>
                    <li className='relative flex gap-6'>
                        <div>
                                {events.map((event) => (
                                    <div>
                                        {/* Dot */}
                                        <div className='before:absolute before:left-[5.5px] before:h-dvh before:max-h-[500px] before:w-[1px] before:bg-[#a68059]'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" className='fill-[#a68059]' viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>
                                        
                                        {/* Content */}
                                        </div>
                                        <div className='pl-6'>
                                        <p className='text-base font-normal leading-none text-gray-400 pb-3'>{event.week}</p>
                                        <h3 className='text-xl text-[#29422d] font-semibold'>{event.title}</h3>
                                        <p className='pb-3 text-base font-normal text-justify'>{event.description}</p>
                                        </div>
                                    </div>
                                ))
                                }
                        </div>
                    </li>
                </ol>
            </div>

        </div>
    )
}

export default SteviaTimeline