import React, { useState} from 'react'

import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";

import chili1 from '../../assets/indianChiliPage/IndianChiliBG.jpg'
import chili2 from '../../assets/indianChiliPage/indianchilifarmer2.jpg'
import chili3 from '../../assets/indianChiliPage/indianchilislider2.jpg'
import zebra from '../../assets/indianChiliPage/sexyzebra.jpg'
import rwandaview from '../../assets/indianChiliPage/rwandaview.jpg'
import stevia1 from '../../assets/indianChiliPage/tianyeju1.jpg'
import stevia2 from '../../assets/indianChiliPage/Stevia1.jpg'


const IndianChiliGallery = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    // List of image URLs
    const images = [
      chili1,chili2,rwandaview,chili3,zebra,stevia1,stevia2
    ];
  
    // Function to go to the next image
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    // Function to go to the previous image
    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };


    return (
        <div className='relative w-full mx-auto overflow-hidden bg-[#6e211d]' id='gallery'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
                {/* image 1 */}
                <img
                src={images[currentIndex]}
                alt={`Slide ${currentIndex + 1}`}
                className="w-full h-[400px] object-cover rounded-3xl transition-transform duration-500"/>
                
                {/* image 2 */}
                <img
                src={images[(currentIndex + 1) % images.length]}
                alt={`Slide ${currentIndex + 2}`}
                className="hidden md:block w-full h-[400px] object-cover rounded-3xl transition-transform duration-500"/>
            </div>
            
            {/* controller */}
            <FaChevronLeft
            className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white p-2 hover:scale-110 duration-300 shadow-lg focus:outline-none"
            onClick={prevSlide} size={50}
            />
            <FaChevronRight
            className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white p-2 hover:scale-110 duration-300 shadow-lg focus:outline-none"
            onClick={nextSlide} size={50}        
            />
            


        </div>
    )
}

export default IndianChiliGallery