import React from 'react'

import farmer1 from '../../assets/indianChiliPage/indianchili-farmer.jpg'

const IndianChiliBasicInfo = () => {
    return (
        <div className='w-full'>
            {/* Small Headers - Table of contents */}
            <div className='flex justify-center items-center p-6 text-lg text-[#a68059]'>
                <p className='px-6 hover:underline hover:text-[#710304]'><a href='#details'>Details</a></p>
                <p className='px-6 hover:underline hover:text-[#710304]'><a href='#story'>Story</a></p>
                <p className='px-6 hover:underline hover:text-[#710304]'><a href='#gallery'>Gallery</a></p>
            </div>

            {/*分割线 */}
            <hr class="border-gray-300 sm:mx-auto" />

            {/* Product Details */}
            <div className='m-auto grid lg:grid-cols-2 text-[#333333]' id='details'>
                <div>
                    <p className='flex justify-center text-xl md:pl-12 pt-12'>Spiciness is </p> 
                    <p className='flex justify-center md:pl-12 text-3xl pt-3 pb-12 text-[#6e211d] font-semibold'>80,000 SHU</p>
                </div>
                
                <ol>
                    <li className='flex justify-evenly text-justify pt-12 text-xl'>Life Cycle: 
                        <span className='text-[#6e211d]'>120 Days</span>
                    </li>
                    <li className='flex justify-evenly text-justify pt-3 text-xl'>Availability: 
                        <span className='text-[#6e211d]'>All Year</span>
                    </li>
                    <li className='flex justify-evenly text-justify pt-3 pb-12 text-xl'>Location: 
                        <span className='text-[#6e211d]'>Rwanda</span>
                    </li>
                </ol>
            </div>
            
            {/*分割线 */}
            <hr class="border-gray-300 sm:mx-auto" />

            <div className='m-auto grid lg:grid-cols-2 gap-4'>
                {/* Details Content */}
                <div className='p-12'>
                    <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>What is Indian Teja Chili?</h2>
                    <p className='text-base text-[#333333] pb-6'>Indian Teja Chili is celebrated for its premium quality and intense flavor, making it the ideal choice for enhancing your dishes with authentic Indian spice. Sourced directly from India, this chili is perfect for adding depth to hot pot seasoning and various culinary creations, ensuring both authenticity and exceptional taste.</p>
                    <h2 className='text-xl text-[#6e211d] font-semibold pb-3'>What makes Rwanda so unique for growing chili?</h2>
                    <p className='text-base text-[#333333]'>Rwanda’s distinctive agricultural environment cultivates chili peppers of exceptional quality. Grown at high altitudes of 1600 meters, our peppers benefit from minimal pest issues and a long, dry season that further reduces pest survival. The fertile soil near the East African Rift Valley, enriched with essential minerals, provides an ideal foundation for growth. Our commitment to natural farming practices means we use minimal chemical fertilizers and exclusively eco-friendly pesticides. With no industrial pollution and a strict ban on plastics in Rwanda, our chili peppers embody the pure, organic flavor of Rwandan produce.</p>
                </div>
                
                {/* Farmer Image */}
                <div>
                    <img className="w-auto h-50 p-12 hover:scale-105 duration-300 items-center"
                    src={farmer1} alt="chili farmer1" />
                </div>
            </div>
            
        </div> 
    )
}

export default IndianChiliBasicInfo