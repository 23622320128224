import React from 'react'
import indianchiliBG from '../../assets/indianChiliPage/IndianChiliBG2.jpg'

const IndianChiliIntro = () => {
    return (
        <div className='w-full h-screen'>
            {/*background img*/}
            <img className='top-0 left-0 w-full h-screen object-cover' src={indianchiliBG} alt="Indian Chili Background" />
            {/*background black mask 蒙版*/}
            <div className='bg-black/40 absolute top-0 w-full h-screen' />

            {/* Text */}
            <div className='absolute top-0 w-full h-full flex justify-center items-center text-white'>
                <div>
                    <p className='p-3 text-xl md:text-4xl'>The Pinnacle of Authentic Indian Spice</p>
                    <h1 className='flex justify-center font-bold text-5xl md:text-7xl drop-shadow-2xl'>Indian Teja Chili</h1>
                </div>
            </div>

        </div>
    )
}

export default IndianChiliIntro