import React from 'react'
import bg from '../assets/background2.jpg'

const Hero = () => {
    return (
        <div className='w-full h-screen' id='home'>
            {/*background img*/}
            <img className='top-0 left-0 w-full h-screen object-cover' src={bg} alt="background" />
            {/*background black mask 蒙版*/}
            <div className='bg-black/30 absolute top-0 w-full h-screen' />

            {/* Text */}
            <div className='absolute top-0 w-full h-full flex flex-col justify-center text-white'>
                <div className='md:left-[10%] max-w-[1100px] m-auto absolute p-4'>
                    <p className='text-2xl md:text-4xl'>FarmFresh International Ltd</p>
                    <h1 className='font-bold text-3xl md:text-5xl drop-shadow-2xl'>Grow Organic, Harvest the Future</h1>
                    <p className='max-w-[60%] drop-shadow-2xl py-2 text-lg md:text-xl mt-3'>Spanning across 500 hectares, our farm is nestled within the heart of Rwanda, Africa. We specialize in the cultivation of chili peppers and stevia, committed to delivering high-quality, healthy, and organic agricultural products. </p>
                    <button className='bg-white text-black hover:bg-black hover:text-white font-bold mt-6'>
                        <a href="#contact">Contact Us</a></button>
                </div>
            </div>

        </div>
    )
}

export default Hero