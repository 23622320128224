import React from 'react'

import Navbar from '../Navbar'
import Contact from '../Contact'
import Footer from '../Footer'
import SteviaIntro from '../Stevia/SteviaIntro'
import SteviaBasicInfo from '../Stevia/SteviaBasicInfo'
import IndianChiliGallery from '../IndianChili/IndianChiliGallery'
import SteviaTimeline from '../Stevia/SteviaTimeline'


const Stevia = () => {
    return (
        <div>
            <Navbar />
            <SteviaIntro />
            <SteviaBasicInfo />
            <SteviaTimeline />
            <IndianChiliGallery />
            <Contact />
            <Footer />
        </div>
    )
}

export default Stevia