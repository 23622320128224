import React from 'react'

import Navbar from '../Navbar'
import Contact from '../Contact'
import Footer from '../Footer'
import IndianChiliIntro from '../IndianChili/IndianChiliIntro'
import IndianChiliBasicInfo from '../IndianChili/IndianChiliBasicInfo'
import ChaotianChiliTimeline from '../ChaotianChili/ChaotianChiliTimeline'
import IndianChiliGallery from '../IndianChili/IndianChiliGallery'



const IndianChili = () => {
    return (
        <div>
            <Navbar />
            <IndianChiliIntro />
            <IndianChiliBasicInfo />
            <ChaotianChiliTimeline />
            <IndianChiliGallery />
            <Contact />
            <Footer />
        </div>
    )
}

export default IndianChili