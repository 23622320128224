import React from 'react'

import farm from '../../assets/Stevia/SteviaFarm.jpg'

const SteviaBasicInfo = () => {
    return (
        <div className='w-full'>
            {/* Small Headers - Table of contents */}
            <div className='flex justify-center items-center p-6 text-lg text-[#a68059]'>
                <p className='px-6 hover:underline hover:text-[#710304]'><a href='#details'>Details</a></p>
                <p className='px-6 hover:underline hover:text-[#710304]'><a href='#story'>Story</a></p>
                <p className='px-6 hover:underline hover:text-[#710304]'><a href='#gallery'>Gallery</a></p>
            </div>

            {/*分割线 */}
            <hr class="border-gray-300 sm:mx-auto" />

            {/* Product Details */}
            <div className='m-auto grid lg:grid-cols-2 text-[#333333]' id='details'>
                <div>
                    <p className='flex justify-center text-xl md:pl-12 pt-12'>Zero Agricultural Residues </p> 
                    <p className='flex justify-center md:pl-12 text-3xl pt-3 pb-12 text-[#29422d] font-semibold'>Organic Planting</p>
                </div>
                
                <ol>
                    <li className='flex justify-evenly text-justify pt-12 text-xl'>Life Cycle: 
                        <span className='text-[#29422d]'>120 Days</span>
                    </li>
                    <li className='flex justify-evenly text-justify pt-3 text-xl'>Availability: 
                        <span className='text-[#29422d]'>All Year</span>
                    </li>
                    <li className='flex justify-evenly text-justify pt-3 pb-12 text-xl'>Location: 
                        <span className='text-[#29422d]'>Rwanda</span>
                    </li>
                </ol>
            </div>
            
            {/*分割线 */}
            <hr class="border-gray-300 sm:mx-auto" />

            <div className='m-auto grid lg:grid-cols-2 gap-4'>
                {/* Details Content */}
                <div className='p-12'>
                    <h2 className='text-xl text-[#29422d] font-semibold pb-3'>What is Stevia?</h2>
                    <p className='text-base text-[#333333] pb-6'>Stevia is a natural sweetener extracted from the leaves of the Stevia rebaudiana plant. It is 200-300 times sweeter than sugar but contains no calories, making it a popular alternative for those looking to cut sugar or manage blood sugar levels. The sweetening power of stevia comes from its steviol glycosides, which do not affect blood glucose. Besides its sweetness, stevia offers potential health benefits, such as antioxidant and anti-inflammatory properties. It is widely used in various foods and beverages as a healthier sweetening option.</p>
                    <h2 className='text-xl text-[#29422d] font-semibold pb-3'>How do we keep Stevia planting process organic?</h2>
                    <p className='text-base text-[#333333]'>To ensure organic stevia cultivation, we implement strict practices. We choose fields that have been free of synthetic pesticides and fertilizers for at least three years and use only natural manure to enrich the soil. Organic pest control methods are employed, and water sources are protected from industrial and environmental pollutants. Harvesting and processing are done manually to avoid contamination from machinery. These practices help maintain the purity of the stevia, ensuring it is free from chemical residues and retains its natural quality.</p>
                </div>
                
                {/* Farm Image */}
                <div>
                    <img className="w-auto h-50 p-12 hover:scale-105 duration-300 items-center"
                    src={farm} alt="Stevia Farm" />
                </div>
            </div>
            
        </div> 
    )
}

export default SteviaBasicInfo